var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "text-gray-700 xl:w-3/4 m-auto" }, [
      _c("div", { staticClass: "sm:flex p-10" }, [
        _c("img", {
          staticClass: "m-auto sm:mx-0 w-24 h-24 sm:mr-10",
          attrs: {
            src: require("@/assets/images/emojis/641.png"),
            alt: "emoji",
          },
        }),
        _c("div", { staticClass: "sm:w-4/6" }, [
          _c(
            "h1",
            {
              staticClass:
                "mb-2 font-bold text-center text-xl text-primary-normal",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("checkout.creditCard_success.thanks")) + " "
              ),
              _c("strong", [
                _vm._v(_vm._s(_vm.plan) + " " + _vm._s(_vm.recurrence)),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "text-center text-l sm:text-2xl md:w-2/3 m-auto" },
        [
          _c("p", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("checkout.creditCard_success.process", {
                  plan: this.plan,
                  recurrence: this.recurrence,
                })
              ),
            },
          }),
          _c("br"),
          _c(
            "div",
            { staticClass: "w-full text-center mt-10" },
            [
              _c("submit-button-default", {
                attrs: {
                  text: _vm.$t("understood"),
                  showIcon: false,
                  fatButton: true,
                  "data-cy": "checkout-success-btn",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.next()
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }