<template>
    <div>
        <CheckoutHeader :back-button-action=false class="-mb-4">
            <template v-slot:header>
                <template>
                    <span data-cy="choose-wizard_control-text">
                        4 {{ $t("checkout.of") }} 4 &mdash;
                    </span>
                </template>
                <strong data-cy="choose-wizard_control_choose-text">
                    {{ $t("checkout.bankslip_finish") }}</strong
                >
            </template>
            <template v-slot:sub-header>
                {{ $t("plan") }}
                <strong data-cy="payment_method-plan-text"
                    >{{ getDefaultPlanName }}
                    {{ $t("recurrences_num")[getChosenPlan?.recurrencePeriod] }}
                </strong>
                &mdash;
                <span data-cy="choose-plan_price-text" v-html="planPrice" />
            </template>
        </CheckoutHeader>
        <div class="alert alert-info" role="alert">
            <h4 class="alert-heading"><b>{{ $t("checkout.bankslip_alert_heading") }}</b></h4> 
            {{ $t("checkout.bankslip_alert_info") }}
        </div>
        <div class="my-5">
            <iframe :src="getBankslipUrl" title="description" frameborder="0" class="iframe">
    </iframe>


            <p class="text-center mb-3 mt-5 text-lg">
                {{ $t("checkout.send_bankslip") }}
            </p>
        </div>

        <div class="mt-6">
            <submit-button-default
                :text="$t('understand')"
                data-cy="checkout_bankslip-submit-btn"
                @click.native="backBtnAction(); eventGtm('custom.bankslip-Success')"
            />
        </div>
    </div>
</template>

<script>
import { CHECKOUT_BANKSLIP_VIEW, HOME_VIEW } from "@/router/constants";
import {
    CHECKOUT_MODULE,
    GET_RECURRENCE,
    GET_PLAN,
    GET_CHOSEN_PLAN,
    GET_ORDER,
} from "../store/constants/checkout";
import { mapGetters, mapActions } from "vuex";
import CheckoutHeader from "@/components/Checkout/Header.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { STORE, STORE_MODULE } from "@/store/constants/store";
import { Currency } from "@/constants";


export default {
    name: CHECKOUT_BANKSLIP_VIEW,
    components: {
        CheckoutHeader,
        SubmitButtonDefault,
    },
    data() {
        return {
            copyButtonText: this.$t("copy"),
            copied: false,
            pixCopied: false
        };
    },
    computed: {
        ...mapGetters({
            getRecurrence: `${CHECKOUT_MODULE}/${GET_RECURRENCE}`,
            getPlan: `${CHECKOUT_MODULE}/${GET_PLAN}`,
            getChosenPlan: `${CHECKOUT_MODULE}/${GET_CHOSEN_PLAN}`,
            getProfile: `${STORE_MODULE}/${STORE}`,
            getOrder: `${CHECKOUT_MODULE}/${GET_ORDER}`,
            isDesktop: `isDesktop`,
            eventGtm: 'eventGtm',

        }),
        planPrice() {
            return `${Currency[this.getChosenPlan?.corrency]} ${this.getChosenPlan?.price}<small>,00
        <em>${
            this.$t("recurrences_freq_num")[
                this.getChosenPlan?.recurrencePeriod
            ]
        }</em>
        </small>`;
        },
        getDefaultPlanName() {
            return this.$t("plan_default_name")[this.getChosenPlan?.name];
        },
        getBankslipUrl() {
            return (
                this.getOrder?.bankSlip?.url ||
                this.getProfile.subscription.last_bankslip_url
            );
        },
        getTypeableBarcode() {
            return (
                this.getOrder?.bankSlip?.typeableBarcode ||
                this.getProfile.subscription.last_bankslip_barcode
            );
        },
        getCopyPastPIX() {
          return this.getOrder?.pix?.copyPaste
        },
        getPixQRCODE() {
          return this.getOrder?.pix?.qrCodeImageBase64
        }
    },
    async mounted() {
        this.$toast.destroy();
    },
    methods: {
        ...mapActions({}),

        backBtnAction() {
            if (this.isDesktop) {
                return alert("@@finalizarfluxoassinatura");
            }
            this.$router.push({
                name: HOME_VIEW,
            });
            location.reload();
        },
        copyToClipboard(target) {
            const barCode = this.getOrder?.bankSlip?.typeableBarcode;
            const pixCopyPaste = this.getOrder?.pix?.copyPaste
            let text

            if (target === 'barcode') {
              text = barCode
            } else {
              text = pixCopyPaste
            }

            navigator.clipboard.writeText(text).then(
                () => {
                  if (target === 'barcode') {
                    this.copied = true;
                  } else {
                    this.pixCopied = true
                  }

                  setTimeout(() => {
                    this.copied = false
                    this.pixCopied = false
                  }, 500)
                },
                (err) => {
                    this.$fire({
                        text: err,
                        type: "error",
                        timer: 6000,
                        customClass: "alert-error-1",
                    });
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.resp-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.typable_barcode {
    @apply flex flex-no-wrap border px-1 text-sm justify-between place-items-center;
    .btn-action {
        @apply cursor-pointer border px-2 mr-2 block text-center;
    }
    .btn-copy {
        @apply text-xs text-white text-black bg-white ml-2;
        &:hover {
            @apply bg-gray-100;
        }
    }
    .btn-pdf {
        @apply bg-blue-500 text-white border-blue-600;
        &:hover {
            @apply bg-blue-700;
        }
    }
    .icon {
        @apply flex-initial m-2 h-8 w-8;
    }
    .line {
        @apply text-lg text-center whitespace-no-wrap overflow-hidden flex-grow;
        text-overflow: ellipsis;
    }
    .lineCompact {
        @apply text-xs;
    }
}
@screen sm {
    .typable_barcode {
        .btn-action {
            @apply py-1;
        }
        .icon {
            @apply h-6 w-6;
        }
        .btn-action {
            @apply text-sm;
        }
    }
}
@screen md {
    .typable_barcode {
        .btn-action {
            @apply py-1 w-24;
        }
        .icon {
            @apply h-8 w-8;
        }
    }
}
@screen lg {
    .typable_barcode {
        .btn-action {
            @apply py-2 w-28;
        }
        .icon {
            @apply h-10 w-10;
        }
    }
}
@screen xl {
    .typable_barcode {
        .btn-action {
            @apply py-2 w-32;
        }
        .icon {
            @apply h-12 w-12;
        }
    }
}
  .iframe {
    width: 100%;
    zoom: 8;
  }
</style>
