<template>
    <Success />
</template>

<script>
import Success from "@/components/Checkout/Success.vue";
import { CHECKOUT_CREDIT_CARD_SUCCESS } from "@/router/constants";
export default {
    name: CHECKOUT_CREDIT_CARD_SUCCESS,
    components: { Success }
};
</script>
<style>
</style>
